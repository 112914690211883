import { imagemURL } from "../../../services/variables";
import { useState } from "react";
import { NomePortal, ufPortal } from "../../../services/variables";

export default function CardInforOffice(props) {
  const [detailsBox, setDetailsBox] = useState(false);
  const { data } = props;

  return (
    <div className="box_card">
      <div className="card">
        <div className="representant_img">
          <img
            src={
              data.IMG === "logo.png"
                ? "/imagens/logo.png"
                : imagemURL + data.IMG
            }
            className="card__image"
            alt="Representante"
          />
        </div>
        <div className="card__details">
          <div className="card__header">
            <img className="card__thumb" src={"/imagens/logo.png"} alt="logo" />
            <div className="card__header-text">
              <h3 className="card__title">{data.NOME}</h3>
              <span className="card__status">{data.CARGO_NOME}</span>
            </div>
          </div>
          <div className="card__description">
            <p>
              <strong>Endereço:</strong> {data.ENDERECO}
            </p>
            <p>
              <strong>Cidade:</strong> {NomePortal} {"-"} {ufPortal}
            </p>

            <p>
              <strong>Cep:</strong> {data.CEP}
            </p>
            <p>
              <strong>Email:</strong> {data.EMAIL}
            </p>
            <p>
              <strong>Telefone de contato:</strong> {data.TELEFONE}
            </p>
            <p>
              <strong>Atendimento ao público:</strong> {data.ATENDIMENTO}
            </p>
            <div
              className="Button_details"
              onClick={() => {
                setDetailsBox(!detailsBox);
              }}
            >
              <p>
                <strong>Texto de atribuição</strong>
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="0.8rem"
                height="0.8rem"
                fill="currentColor"
                className="bi bi-plus-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      {detailsBox && (
        <>
          <div className="card_plus">
            <div onClick={() => setDetailsBox(false)} className="box-detalis">
              <div className="Title_card_plus">
                <div className="title-plus-img">
                  <img
                    className="card__thumb"
                    src={"/imagens/logo.png"}
                    alt="logo Portal"
                  />
                </div>
                <div className="tilte-plus-txt">
                  <h3 className="card__title">{data.NOME}</h3>
                  <span className="card__status">{data.CARGO_NOME}</span>
                </div>
              </div>
              <div className="plus_icon_icon">
                <img
                  src="/icons/close.svg"
                  alt="sair"
                  className="icon_close_plus"
                />
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: data.TEXTO_ATRIBUICAO }}
              className="plus_txt"
            ></div>
          </div>
        </>
      )}
    </div>
  );
}
