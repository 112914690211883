
import boxStyled from '../../../../styles/module/transparency/diary.module.css'

export default function BoxDetailDiary(props){
    
    
    return(
    <div className={boxStyled.boxDetail}>
        <div className={boxStyled.headerDetail}>
            <h6>Edital {props.data.title}</h6>
            <svg  className={boxStyled.iconeDetail} 
            onClick={props.handle}
            viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
        </div>
        <div className={boxStyled.bodyDetail}>
            <p>{props.data.body == '' ?<>Nenhuma informação extra</> : <>{props.data.body}</>}</p>
        </div>
    </div>)
}