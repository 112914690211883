const idPortalApi = "aa4c80947d387e77e2f59614745c76c4f77b6860";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://cmolindanovadomaranhao.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmolindanovadomaranhao.ma.gov.br",
  NomePortal: "Olinda Nova do Maranhão",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 4, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `https://www.planalto.gov.br/ccivil_03/leis/l9504.htm`,
  link_localize: `https://maps.app.goo.gl/Da8PR2t9cjM2RHS4A`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15937.423721526102!2d-45.0025469!3d-2.9984857!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7f58808ae3ea769%3A0x9a3a3bae3cd7099a!2sOlinda%20Nova%20do%20Maranh%C3%A3o%2C%20MA%2C%2065223-000!5e0!3m2!1spt-BR!2sbr!4v1721844469344!5m2!1spt-BR!2sbr",
};
