import NavMenu from "../../../../components/public/others/Navbar";
import NavMenuCM from "../../../../components/public/others/NavbarCM";
import Footer from "../../../../components/public/others/Footer";
import { TipoPortal } from '../../../../services/variables';
import FromSubmitOuvidoria from './../../../../components/public/transparency/ouvidoria/FormOuvidoria';


export default function Ouvidoria(props) {
  const route = props.route
  
  return (
    <> 
     {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
 {TipoPortal == 3 ? <>
      <NavMenu/>
        </>:<>
      <NavMenuCM/>
      </>}
      <div className="section-title rotes_pages">
      <h6>Início {route}</h6>
      </div>
      <div className="section-title-portarias">
      <h4>Ouvidoria</h4>
      </div>
      <FromSubmitOuvidoria></FromSubmitOuvidoria>
      
    </>
  )
}