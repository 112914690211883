import React, { useState } from 'react';
import styled from '../../../../styles/module/others/organograma.module.css';
import api from '../../../../services/api';
import { useEffect } from 'react';
import ButtonTypeHidden from '../utils/ButtonTypeHidden';
import { Table } from 'react-bootstrap';
import Loading from '../Loading';


 
export default function FileAtos () {
    const [dataFiles, setdataFiles] = useState([])
    const [showLoading, setShowLoading] = useState(true)
  useEffect(()=>{
    api.post('/atosAdministrativos/list',
            {"type":"Estrutura Administrativa"})
    .then(({data})=>{

      if(!data.err){
        if(data.res){
          setdataFiles(data.res)
        }
      }
    }).catch((err)=>{
      // console.log(err)
    }).finally(()=>{
      setShowLoading(false)
    })
  },[])    

  const dateRender = (dateReq) => {
    const nameMonth = {
      0: "01",
      1: "02",
      2: "03",
      3: "04",
      4: "05",
      5: "06",
      6: "07",
      7: "08",
      8: "09",
      9: "10",
      10: "11",
      11: "12",
    };
    const date = new Date(dateReq);
    if (dateReq != "") {
      date.setDate(date.getDate() + 1);

      var day = date.getDate();
      if (date.getDate() < 10) {
        var day = "0" + date.getDate();
      }
      const formatDate =
        day + "/" + nameMonth[date.getMonth()] + "/" + date.getFullYear();
      return formatDate;
    } else {
      return "";
    }
  };

  return (
    <>
    {showLoading ? <Loading/> :
    
    <div 
    // className={styled.backgroundOutsideCard}
    >
     


       {/* <section className={styled.table} > */}
       {/* <section> */}

      <div className="section-title">
        <div className="cardTitle">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"  className={styled.iconeSubTitle} viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"/>
          </svg>
          <h6 className={styled.subTitle} >
          Documentos</h6>
        </div>
      </div>
       <section className={styled.tableOrganograma} >

       {/* <section className="table-show-portarias"> */}
            <br />
            <Table responsive>
              <thead>
                <tr>
                  <th className={styled.columTitle}>Data</th>
                  <th className={styled.columTitle}>Titulo</th>
                  <th className={styled.columTitle}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {dataFiles != undefined ? (
                  <>
                    {dataFiles.map((data, index) => (
                      <tr key={index}>
                        <td className="data-td-portais portarias-td">
                          {dateRender(data.DATA)}
                        </td>
                        <td className="title-td-portais portarias-td">
                          {data.TIPO == "Plano anual de contratações" ? (
                            <b>{data.TITULO}</b>
                          ) : (
                            data.TITULO
                          )}
                        </td>
                        <td className="acoes-td-portais portarias-td">
                          <ButtonTypeHidden
                            link={`https://administracaopublica.com.br/downloads?n=${data.CNPJ.replace(
                              "/",
                              ""
                            )
                              .replace(".", "")
                              .replace(".", "")
                              .replace("-", "")}&a=${data.ARQUIVO}`}
                            targetBlank={true}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              className="icon-download-DOWNLOAD"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </ButtonTypeHidden>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <></>
                )}

              </tbody>
            </Table>
        </section>
        {dataFiles.length == 0 && (
          <div className={styled.noneResult}>
            <h6 >
              Nenhum Resultado Encontrado
            </h6>
          </div>
        )}
           
            
    </div>
    }
    </>
  );
};

