
export default function TitleMain(props){

    const {title, route} = props

    return(
    <>
    <div className="section-title rotes_pages">
      <h6>{route}</h6>
      </div>
      <div className="section-title-portarias">
      <h4>{title}</h4>
      </div>
  
    </>)
}