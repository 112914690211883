import React, {useCallback, useEffect,useState } from 'react';
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  // MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
} from 'reactflow';
import dagre from 'dagre';
import 'reactflow/dist/style.css';
import styled from '../../../../styles/module/others/organograma.module.css';
import CardInforOrganograma from './CardInfo';
   

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 150;
const nodeHeight = 100;

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction }); //Define a direção do layout para o grafo dagre

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph); //para calcular o layout dos nós no grafo.

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

const LayoutFlow = (props) => {

  const [visibilityChartInformation, setVisibilityChartInformation] = useState(false);
  const [dataInfor, setDataInfor] = useState({});


  const [nodes, setNodes, onNodesChange] = useNodesState(props.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(props.edges);

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    nodes,
    edges
  );

  const nodess = props.nodes;
  const edgess = props.edges;

  const handleSelectChart = (nodes)=>{

    nodes.map((node) => {
      if(node.selected){
        const infoNode = node.data.element 
        if(infoNode != undefined){
          const idNode =  infoNode.ID
          setDataInfor(infoNode)
          handleClickCardInfo(true)
        }
      }
    })
  }

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({ ...params, type: ConnectionLineType.SmoothStep, animated: true }, eds)
      ),
    []
  );
  
const handleClickCardInfo = useCallback((e)=>{
  setVisibilityChartInformation(e)
},[visibilityChartInformation])

return (<>
    
  <div className={styled.boxOrganograma} style={{ height: 600 }}>


  
  <div className="section-title">
        <div className="cardTitle">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"  className={styled.iconeSubTitle} viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"/>
          </svg>
          <h6 className={styled.subTitle} >
          Estrutura Organizacional</h6>
        </div>

        <div className={styled.msgAlertOrganograma}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
          <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
          <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
          </svg>
        <p>Mova o organograma abaixo com o uso do mouse. Dê zoom e veja os detalhes de cada estrutura clicando sobre o nome</p>
        </div>
      </div>
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onClick={()=>handleSelectChart(nodes)}
      onConnect={onConnect}
      defaultViewport={ { x: 0, y: 3, }}
      connectionLineType={ConnectionLineType.SmoothStep}
      fitView
    >
      <Controls  />
      <Background color="#000000a6" gap={16} />
    </ReactFlow> 
    </div>
  

  {visibilityChartInformation &&
    <CardInforOrganograma
    id={Math.floor(new Date().getTime() / 1000)}
    key={Math.floor(new Date().getTime() / 1000)}
    value={dataInfor}
    onClick={handleClickCardInfo}
    />
  } 
</>);
};

export default LayoutFlow
