import "../../../styles/admin/news.css";

import React, { useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { useEffect } from "react";
import { Button } from "react-bootstrap";

import { imagemURL } from "../../../services/variables";
import Loading from "../../public/others/LoadingFull";

export default function AdminNews(props) {
  const slugNoticia = props.id;
  const editorRef = useRef(null);
  const navigate = useNavigate();

  const [category, setCategory] = useState(null);

  const [idNoticia, setIdNoticia] = useState("");
  const [title, setTitle] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [textLessTag, setTextLessTag] = useState("");
  const [categorySelect, setCategorySelect] = useState("");
  const [existImg, setExistImg] = useState(true);
  const [imgDB, setImgDB] = useState("");
  const [img, setImg] = useState("");
  const [multipleImg, setMultipleImg] = useState([]);
  const [newMultipleImg, setNewMultipleImg] = useState([]);
  const [date, setDate] = useState("");
  const [visible, setVisible] = useState("");
  const [typeNewsSystem, setTypeNewsSystem] = useState("");

  const [removeLoading, setRemoveLoading] = useState(false); //loading

  const dateRender = (dateReq) => {
    const nameMonth = {
      0: "01",
      1: "02",
      2: "03",
      3: "04",
      4: "05",
      5: "06",
      6: "07",
      7: "08",
      8: "09",
      9: "10",
      10: "11",
      11: "12",
    };
    const date = new Date(dateReq);
    if (dateReq == "") {
      return "";
    } else {
      date.setDate(date.getDate() + 1);

      var day = date.getDate();
      if (date.getDate() < 10) {
        var day = "0" + date.getDate();
      }
      const formatDate =
        +date.getFullYear() + "-" + nameMonth[date.getMonth()] + "-" + day;
      return formatDate;
    }
  };

  //render categorias
  useEffect(() => {
    api.get("/category").then((res) => {
      setCategory(res.data.res);
      setRemoveLoading(true);
    });
  }, []);
  useEffect(() => {
    const img2 = img || " "; // se imag for undefined, a img2 será uma string vazia

    if (img2 !== " ") {
      const resultType = img2.type.split("/");
      if (resultType[0] === "image") {
        updateImgMain(imgDB);
        //aceita apenas no formato de imagem
        //se for imagem tá tudo ok
      } else {
        alert("Selecione um arquivo do tipo imagem");
        setImg("");
      }
    }
  }, [img]);
  useEffect(() => {
    newMultipleImg.forEach((file) => {
      const img2 = file || " "; // se imagem(file) for undefined, a img2 será uma string vazia

      if (img2 !== " ") {
        const resultType = img2.type.split("/");
        if (resultType[0] === "image") {
          //aceita apenas no formato de imagem
          //se for imagem tá tudo ok
        } else {
          alert("Selecione um arquivo do tipo imagem");
          setMultipleImg([]);
        }
      }
    });
  }, [newMultipleImg]);
  useEffect(() => {
    api
      .get(`/news/edit/show/${slugNoticia}/`)
      .then((response) => {
        const result = response.data;
        if (result.err) {
          console.log("ERRO");
        } else {
          setIdNoticia(result.res[0].ID);
          setTitle(result.res[0].TITULO);
          setBodyText(result.res[0].CORPO_TEXTO);
          setImgDB(result.res[0].IMG);
          setCategorySelect(result.res[0].ID_CATEGORIA);
          setMultipleImg(result.res[0].MULTIPLE_IMG);
          setDate(result.res[0].DATA_POST);
          setVisible(result.res[0].DISPONIVEL_VISUALIZACAO);
          setTypeNewsSystem(result.res[0].NOTICIA_SISTEMA);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [slugNoticia]);

  const updateImgMain = (nameRemove) => {
    setRemoveLoading(false);

    const dataJson = new FormData();
    dataJson.append("id", idNoticia);
    dataJson.append("img", img);
    dataJson.append("nameImgOld", nameRemove);
    console.log(nameRemove);
    console.log(img);
    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    api
      .post(`/news/update/img/${idNoticia}`, dataJson, headers)
      .then((response) => {
        const result = response.data;
        if (result.err) {
          console.log("ERRO");
          alert("A Imagem não foi substituída");
          setRemoveLoading(true);
        } else {
          setExistImg(true);
          setImg("");
          setImgDB(result.name);
          //console.log(result)
          setRemoveLoading(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setRemoveLoading(true);
      });
  };
  const removeImgMain = () => {
    setRemoveLoading(false);
    setExistImg(false);
    if (imgDB === "logo.png") {
      console.log("logo");
    } else {
      updateImgMain(imgDB);
    }
    setRemoveLoading(true);
  };

  const deleteMulImg = (j, item) => {
    setRemoveLoading(false);
    multipleImg.splice(j, 1);
    setMultipleImg(multipleImg);

    api
      .get(`/news/edit/img/${idNoticia}/${item}`)
      .then((response) => {
        const result = response.data;
        if (result.err) {
          console.log("ERRO");
          alert("Imagem não foi apagada");

          setRemoveLoading(true);
        } else {
          console.log("SUCESSO");

          api
            .get(`/news/edit/show/${slugNoticia}/`)
            .then((response) => {
              const result = response.data;
              if (result.err) {
              } else {
                setMultipleImg(result.res[0].MULTIPLE_IMG);
                setRemoveLoading(true);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const textNews = () => {
    if (editorRef.current) {
      setBodyText(editorRef.current.getContent());
    }
  };

  const newRenderMultiImg = (key) => {
    var result = [];

    for (let j = 0; j < newMultipleImg.length; j++) {
      result.push(
        <a
          href={URL.createObjectURL(newMultipleImg[j])}
          target="_blank"
          rel="noopener noreferrer"
          title="Visualizar imagem"
          key={j}
        >
          <img
            src={URL.createObjectURL(newMultipleImg[j])}
            alt="imagem selecionada"
            className="image-preview"
          />
        </a>
      );
    }

    return <>{result}</>;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRemoveLoading(false);

    const dataJson = new FormData();
    dataJson.append("id", idNoticia);
    if (title !== "") {
      dataJson.append("title", title);
    } else {
      dataJson.append("title", "Nova Notícia");
    }
    dataJson.append("bodyText", bodyText); ///body
    dataJson.append("textLessTag", textLessTag); //.substr(0,300)
    dataJson.append("img", imgDB);
    dataJson.append("date", date);
    dataJson.append("typeNewsSystem", typeNewsSystem); // Vai definir se o tipo é system ou não // tá definido aqui para definir se o slug deve ou sofre update no DB

    if (visible == true || visible == 1) {
      dataJson.append("visible", 1);
    } else if (visible == false || visible == 0) {
      dataJson.append("visible", 0);
    }

    if (categorySelect === "") {
      //setar valor padrão para categoria geral
      setCategorySelect(1);
      dataJson.append("category", categorySelect);
    } else {
      dataJson.append("category", categorySelect);
    }
    newMultipleImg.forEach((file) => {
      dataJson.append("multipleImg", file);
    });

    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    await api
      .post(`/news/update/${slugNoticia}`, dataJson, headers)
      .then((res) => {
        alert("Atualizado");
        navigate("/admin/noticias/painel");
      })
      .catch((err) => {
        alert("Ocorreu um erro, tente novamente!!!");
      });
  };

  return (
    <div className="container-noticia">
      {!removeLoading && <Loading />}
      <div className="sub-header-news">
        <h2>Criar Notícia</h2>
        <Button
          className="btn-success"
          variant="primary"
          href="/admin/noticias/painel"
        >
          Voltar para lista
        </Button>
      </div>
      <main>
        <form onSubmit={handleSubmit} className="form-admin-news">
          <label className="form-input">
            Titulo da notícia:
            <input
              type="text"
              name="title"
              className="form-input-news"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </label>

          {!existImg ? (
            <label className="input-file-container">
              Imagem:
              <input
                type="file"
                name="imgTop"
                accept="image/*"
                className="form-input-news"
                onChange={(e) => setImg(e.target.files[0])}
              />
              {img !== "" && img !== undefined ? (
                <a
                  href={URL.createObjectURL(img)}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Visualizar imagem"
                  className="img-show-select"
                >
                  <img
                    src={URL.createObjectURL(img)}
                    alt="imagem selecionada"
                    className="img-show-select select-top"
                  />
                  Visualizar Imagem
                </a>
              ) : (
                "Selecionar imagem"
              )}
              <p>Buscar</p>
            </label>
          ) : (
            <label className="input-file-container">
              {imgDB == null ? null : (
                <div className="image-preview-container ">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-show-select"
                    href={
                      imgDB === "logo.png"
                        ? "/imagens/logo.png"
                        : imagemURL + imgDB
                    }
                  >
                    <img
                      src={
                        imgDB === "logo.png"
                          ? "/imagens/logo.png"
                          : imagemURL + imgDB
                      }
                      alt="imagem selecionada"
                      className="img-show-select select-multiple"
                    />
                  </a>
                </div>
              )}
              <Button
                onClick={() => removeImgMain()}
                className="button-selecionar"
              >
                Selecionar Outra Imagem
              </Button>
            </label>
          )}
          <label className="form-input">
            Categoria:
            {category == null ? null : (
              <select
                name="select form-input-news"
                defaultValue={categorySelect}
                onChange={(e) => setCategorySelect(e.target.value)}
              >
                {category.map((item, i) => (
                  <option value={item.ID} key={i}>
                    {item.NOME}
                  </option>
                ))}
              </select>
            )}
          </label>

          <label className="form-input">
            Data:
            <input
              type="date"
              name="date"
              className="input-fit"
              value={dateRender(date)}
              onChange={(e) => setDate(e.target.value)}
            />
          </label>

          <label className="form-input checkbox">
            <input
              type="checkbox"
              name="select-visible"
              id="checkbox-visible"
              checked={visible}
              onChange={() => setVisible(!visible)}
            />
            <span className="checkmark"></span>
            <p>Status: {visible ? "Visível ao público" : "Não Visível"}</p>
          </label>

          <label className="form-input">
            Notícia:
            <Editor
              name="bodyText"
              onInit={(evt, editor) => (editorRef.current = editor)}
              className="form-input-news"
              initialValue={bodyText}
              apiKey="khlc1joor43wmnaib9qvcd2kw542nz3v89gokd4n4op2xyeq"
              init={{
                plugins:
                  "anchor autolink charmap codesample emoticons image link lists searchreplace visualblocks wordcount linkchecker",
                toolbar:
                  "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image  | numlist bullist indent outdent | emoticons charmap | removeformat",
                language: "pt_BR",
              }}
              onEditorChange={(newValue, editor) => {
                setTextLessTag(editor.getContent({ format: "text" }));
              }}
            />
          </label>

          <label className="input-file-container">
            <input
              type="file"
              multiple="multiple"
              name="img-multiple"
              accept="image/*"
              className="custom-input-file"
              onChange={(e) => setNewMultipleImg(Array.from(e.target.files))}
            />

            {newMultipleImg.length !== 0 && (
              <div className="card-img-viwies-multiple">
                {newRenderMultiImg()}
              </div>
            )}
            <Button className="button-selecionar">
              Selecionar mais Imagens
            </Button>
          </label>

          {/* pre-visualização das imagens já cadastradas */}
          {multipleImg[0] !== null ? (
            <div className="image-preview-container">
              {multipleImg.map((item, j) => {
                return (
                  <>
                    <a
                      href={imagemURL + item}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Visualizar imagem"
                      className="link-img-show-select link-select-multiple"
                      key={j}
                    >
                      <img
                        src={imagemURL + item}
                        alt="imagem selecionada"
                        className="image-preview"
                      />
                    </a>
                    <div
                      onClick={() => deleteMulImg(j, item)}
                      className="icon-close-img-news"
                    >
                      <img
                        src="/icons/closeB.svg"
                        alt="icone remover"
                        title="Remover"
                        className="icon-close-svg"
                      />
                    </div>
                  </>
                );
              })}
            </div>
          ) : null}

          <Button
            type="submit"
            className="btn-success"
            variant="primary"
            onClick={textNews}
          >
            Enviar Atualização
          </Button>
        </form>
      </main>
    </div>
  );
}
