import { TipoPortal } from "../../../../services/variables";
import NavMenu from "../../../../components/public/others/Navbar";
import NavMenuCM from "../../../../components/public/others/NavbarCM";
import Footer from "../../../../components/public/others/Footer";
import styles from "../../../../styles/module/transparency/dados_abertos.module.css";

import buttonsSACOP from "../../../../components/jsons/routesTranparency/routesSacopAndLicitacoes";
import buttonsATOS from "../../../../components/jsons/routesTranparency/routesAtcsAdministrative";
import {
  CSVexports,
  JSONexports,
  XLSexports,
  useGetAtos,
  useGetSacops,
} from "../../../../components/public/transparency/dados-abertos";
import { useState } from "react";

export default function DadosAbertosPage() {
  const [sacop, setSacop] = useState(null);
  const [ato, setAto] = useState(null);
  const { data, isLoading } = useGetAtos(ato);
  const { data: sacopData, isLoading: sacopLoading } = useGetSacops(sacop);

  function handleDownload({ tipo, metodo }) {
    setAto(tipo);
    if (metodo == "json") {
      if (!isLoading && data != undefined && ato !== null) {
        JSONexports({ data: data.res, select: ato });
        return true;
      }
    }
    if (metodo == "xls") {
      if (!isLoading && data != undefined && ato !== null) {
        XLSexports({ data: data.res, select: ato });
        return true;
      }
    } else if (metodo == "csv") {
      if (!isLoading && data != undefined && ato !== null) {
        CSVexports({ data: data.res, select: ato });
        return true;
      }
    }
    return false;
  }

  function handleDownloadSacop({ tipo, metodo, declaracao }) {
    setSacop(tipo);
    if (metodo == "json") {
      if (declaracao != undefined && declaracao != null) {
        setAto(declaracao);
        if (
          !sacopLoading &&
          sacopData != undefined &&
          !isLoading &&
          data != undefined
        ) {
          let concat = [...sacopData, ["DECLARAÇÕES EM SEGUIDA"], ...data.res];
          JSONexports({ data: concat, select: sacop });
        }
        return true;
      } else {
        if (!sacopLoading && sacopData != undefined && !isLoading) {
          JSONexports({ data: sacopData, select: sacop });
        }
        return true;
      }
    }
    if (metodo == "xls") {
      if (declaracao != undefined && declaracao != null) {
        setAto(declaracao);
        if (
          !sacopLoading &&
          sacopData != undefined &&
          !isLoading &&
          data != undefined
        ) {
          let concat = [...sacopData, ["DECLARAÇÕES EM SEGUIDA"], ...data.res];
          XLSexports({ data: concat, select: sacop });
        }
        return true;
      } else {
        if (!sacopLoading && sacopData != undefined && !isLoading) {
          XLSexports({ data: sacopData, select: sacop });
        }
        return true;
      }
    }
    if (metodo == "csv") {
      if (declaracao != undefined && declaracao != null) {
        setAto(declaracao);
        if (
          !sacopLoading &&
          sacopData != undefined &&
          !isLoading &&
          data != undefined
        ) {
          let concat = [...sacopData, ["DECLARAÇÕES EM SEGUIDA"], ...data.res];
          CSVexports({ data: concat, select: sacop });
        }
        return true;
      } else {
        if (!sacopLoading && sacopData != undefined && !isLoading) {
          CSVexports({ data: sacopData, select: sacop });
        }
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {TipoPortal == 3 ? <NavMenu /> : <NavMenuCM />}
      <div className="section-title rotes_pages">
        <div className="section-title-portarias">
          <h4>Dados Abertos</h4>
          <p>Lista das informações abaixo para exportar</p>
        </div>
        <div className={styles.container_butons}>
          {buttonsSACOP.map((i, index) => (
            <div key={index} className={styles.button_rotes}>
              <p>{i.TITLE}</p>
              <div className={styles.acoes}>
                <b
                  onClick={() =>
                    handleDownloadSacop({
                      tipo: i.SELECT,
                      metodo: "json",
                      declaracao: i["SELECT-TYPE-DECLARACAO"],
                    })
                  }
                >
                  JSON
                </b>
                <b
                  onClick={() =>
                    handleDownloadSacop({
                      tipo: i.SELECT,
                      metodo: "xls",
                      declaracao: i["SELECT-TYPE-DECLARACAO"],
                    })
                  }
                >
                  XLS
                </b>
                <b
                  onClick={() =>
                    handleDownloadSacop({
                      tipo: i.SELECT,
                      metodo: "csv",
                      declaracao: i["SELECT-TYPE-DECLARACAO"],
                    })
                  }
                >
                  CSV
                </b>
              </div>
            </div>
          ))}
          {buttonsATOS.map((i, index) => (
            <div key={index} className={styles.button_rotes}>
              <p>{i.TITLE}</p>
              <div className={styles.acoes}>
                <b
                  onClick={() =>
                    handleDownload({ tipo: i.SELECT, metodo: "json" })
                  }
                >
                  JSON
                </b>
                <b
                  onClick={() =>
                    handleDownload({ tipo: i.SELECT, metodo: "xls" })
                  }
                >
                  XLS
                </b>
                <b
                  onClick={() =>
                    handleDownload({ tipo: i.SELECT, metodo: "csv" })
                  }
                >
                  CSV
                </b>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
