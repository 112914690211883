import React from "react";
import styled from "../../../../styles/module/others/organograma.module.css";

export default function CardInforOrganograma({ ...props }) {
  const { value } = props;

  const clickToClose = () => {
    props.onClick(false);
  };

  return (
    <>
      <div className={styled.backgroundOutsideCard}></div>
      <div className={styled.cardInfoOrganograma}>
        <div className={styled.boxTitle}>
          <div className={styled.title}>
            <img className="card__thumb" src={"/imagens/logo.png"} alt="logo" />

            <div className={styled.titleTxt}>
              <h5>{value.TITULO}</h5>
            </div>
          </div>
          <div className={styled.icone}>
            <img
              src="/icons/close.svg"
              alt="sair"
              className="icon_close_plus"
              onClick={(e) => clickToClose(e)}
            />
          </div>
        </div>
        <div className={styled.cardBody}>
          <p>
            <strong>Titular:</strong> {value.NOME_PESSOA}
          </p>
          <p>
            <strong>Endereço:</strong> {value.ENDERECO}
          </p>
          <p>
            <strong>Bairro:</strong> {value.BAIRRO}
          </p>
          <p>
            <strong>Cep:</strong> {value.CEP}
          </p>
          <p>
            <strong>Email:</strong>
            {value.EMAIL}
          </p>
          <p>
            <strong>Telefone:</strong> {value.TELEFONE}
          </p>
          <p>
            <strong>Atendimento ao público:</strong> {value.HORARIO}
          </p>
          <p>
            <strong>Atribuições:</strong>
            <div
              dangerouslySetInnerHTML={{ __html: value.ATRIBUICAO }}
              className={styled.textAtribuicao}
            ></div>
          </p>
        </div>
      </div>
    </>
  );
}
