import Diary from '../../../../components/public/transparency/diary/InforDiary'
import ListDiary from '../../../../components/public/transparency/diary/List'
import NavMenu from  '../../../../components/public/others/Navbar'
import NavMenuCM from  '../../../../components/public/others/NavbarCM'
import Footer from  '../../../../components/public/others/Footer'
import { TipoPortal } from "../../../../services/variables"
import SubTitle from '../../../../components/public/others/title/SubTitle'
import Title from '../../../../components/public/others/title/Title'

export default function DiaryOfficial(){
  
    return(
        <>
    {TipoPortal == 3 ? <>
      <NavMenu/>
        </>:<>
      <NavMenuCM/>
      </>}
     <Title route={'Início / Diário Oficial'} title={'Diário Oficial'} />
    <Diary/>
    <SubTitle subTitle={'Publicações'} border={true}/>
    <ListDiary/>
    <Footer/>
    </>)
}