import { Button, Table } from "react-bootstrap";
import "../../../styles/lists.css";
import { useState, useEffect } from "react";
import api from "../../../services/api";
import Loading from "../../public/others/LoadingFull";

export default function ListBanners() {
  const [Banner, setBanner] = useState([]);
  const [BannerOthers, setBannerOthers] = useState([]);
  const [removeLoading, setRemoveLoading] = useState(false); //loading
  const [resultDelete, setResultDelete] = useState([]);

  useEffect(() => {
    setRemoveLoading(false);

    api.get("/banner/show/all").then((res) => {
      setBanner(res.data.res); // vai pegar apenas os banners desse portal
      setBannerOthers(res.data.res);
      setRemoveLoading(true);
    });
  }, []);
  useEffect(() => {
    setRemoveLoading(false);

    setBanner(Banner.filter((Banner) => Banner.ID !== resultDelete));
    setBannerOthers(
      BannerOthers.filter((Banner) => Banner.ID !== resultDelete)
    );
    setRemoveLoading(true);
    // eslint-disable-next-line
  }, [resultDelete]);

  const clickLoading = () => {
    setRemoveLoading(false);
  };

  const deleteBanner = (idBanner) => {
    const alertConf = window.confirm("Quer deletar ?");
    if (alertConf) {
      setRemoveLoading(false);
      api
        .delete(`/banner/delete/${idBanner}`)
        .then((res) => {
          const result = res.data;
          if (result.err) {
            alert("Erro ao tentar apagar a noticia");
          } else {
            ///const result = Banner.filter((Banner) => Banner.ID !== idBanner);
            setResultDelete(idBanner);
          }
        })
        .catch((err) => {
          alert("Erro, banco de dados");
          setRemoveLoading(true);
        });
    }
  };

  console.log(Banner);

  return (
    <div className="container-noticias">
      {!removeLoading && <Loading />}
      <span>🚨Sistema de Banner passando por melhorias. </span>
      <div className="sub-header-news">
        <h2>Painel de Banners</h2>
        <Button
          className="btn-success"
          variant="primary"
          href={"/admin/banner/new"}
        >
          Criar novo
        </Button>
      </div>

      <div className="container-lista">
        {Banner?.map((Banner) => (
          <ul
            key={Banner.ID}
            className={`noticia-item ${Banner.DISPONIVEL == 1 ? "V" : "O"}`}
          >
            <li title={Banner.LINK} className="title-td">
              {Banner.LINK}
            </li>

            <li className="views-td">
              {Banner.DISPONIVEL === 1 ? "Visivel" : "Oculto"}
            </li>
            <li className="container-botoes">
              {Banner.ID_TIPO == "2" ? null : (
                <>
                  <Button
                    className="btn-Danger"
                    variant="warning"
                    href={"/admin/banner/editar/" + Banner.ID}
                    onClick={() => clickLoading()}
                  >
                    Editar
                  </Button>
                  <Button
                    className="btn-Danger"
                    variant="danger"
                    onClick={() => deleteBanner(Banner.ID)}
                  >
                    Apagar
                  </Button>
                </>
              )}
            </li>
          </ul>
        ))}
      </div>
      {Banner != undefined ? (
        <>
          {Banner.length === 0 && BannerOthers.length === 0 && (
            <p className="resultTxt">Nenhum resultado</p>
          )}
        </>
      ) : (
        <>
          <p className="resultTxt">Nenhum resultado</p>
        </>
      )}
    </div>
  );
}
