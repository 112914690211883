import { useQuery, useMutation, useQueryClient } from "react-query";
import api from "../../../../services/api";
import { useState } from "react";

export function useListNews() {
  const [progress, setProgress] = useState(0);

  const queryClient = useQueryClient();
  const {
    data,
    isError: listError,
    isLoading: listLoading,
  } = useQuery("news", async () => {
    const response = await api.get("/news/admin");
    return response.data.res;
  });

  const {
    mutate,
    isLoading: mutationLoading,
    isError: mutationError,
  } = useMutation(
    async (data) => {
      const confirm = window.confirm("Deseja apagar a noticia ?");
      if (!confirm) {
        return;
      }
      const response = await api.post(
        `/news/delete/${data.SLUG}/${data.IMG}`,
        {},
        {
          onUploadProgress: (progress) => {
            setProgress(Math.round((progress.loaded / progress.total) * 100));
            return progress;
          },
        }
      );
      return response.data;
    },
    {
      retry: 5,
      onSuccess: () => {
        queryClient.invalidateQueries("news");
      },
    }
  );

  return {
    data,
    mutate,
    listError,
    listLoading,
    mutationLoading,
    mutationError,
    progress,
  };
}
