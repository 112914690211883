
 import React from "react";
import NavMenu from "../../../components/public/others/Navbar";
import NavMenuCM from "../../../components/public/others/NavbarCM";
import SearchHome from "../../../components/public/others/search/SearchHome";
import Buttons from "../../../components/public/buttons/ButtonHome"
import Footer from "../../../components/public/others/Footer";
import buttonsNavbar from '../../../components/jsons/jsonButtons'
import buttonsNavbarCM from '../../../components/jsons/jsonButtonsCM'
import { TipoPortal } from "../../../services/variables";
import SubTitle from "../../../components/public/others/title/SubTitle";
import TextAccessibility from "../../../components/public/others/Accessiblity";


 export default function Accessibility() {
  return (
      <>

      {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
      {TipoPortal == 3 ? <>
      <NavMenu/>
      <SearchHome/> 
      <Buttons names={buttonsNavbar}></Buttons> 
      <div className="section-title rotes_pages">
       <h6> Início / Acessibilidade</h6>
      </div>
      <SubTitle subTitle={'Acessibilidade'} border={true}/>
       <TextAccessibility/>
    <Footer></Footer>
      </>:<>
      <NavMenuCM/>
      <div className="spaceDivCM"></div>
      <div className="GroupBottomCM">
      <Buttons names={buttonsNavbarCM}></Buttons> 
      <div className="section-title rotes_pages">
       <h6> Início / Acessibilidade</h6>
      </div>
      <SubTitle subTitle={'Acessibilidade'} border={true}/>
      <TextAccessibility/>
        <Footer></Footer>
      </div>
      </>}
              


</>

  );

}

