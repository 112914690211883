import NavMenu from "../../../components/public/others/Navbar"
import NavMenuCM from "../../../components/public/others/NavbarCM"
import OfficialDiary from "../../../components/public/others/OfficialDiary"
import Footer from "../../../components/public/others/Footer"
import { TipoPortal } from "../../../services/variables";
import React from "react";



 export default function PageOfficialDiary() {

  return (
    <>
      {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
    {TipoPortal == 3 ? <>
      <NavMenu/>    
    </>:<>
      <NavMenuCM/>
    </>}
      <OfficialDiary/>
      <Footer></Footer> 
    </> 
  );

}

