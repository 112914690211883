import CreatNews from "../../../components/admin/news/CreateNews";
import AdminNavbar from "../../../components/admin/Navbar";

export default function AdminNewsPage() {
  return (
    <>
      <AdminNavbar />
      <div>
        <CreatNews />
      </div>
    </>
  );
}
