import AdminNavbar from "../../../components/admin/Navbar";
import ListOffice from "../../../components/admin/office/List";

export default function AdminNewOffice() {
  return (
    <>
      <AdminNavbar />
      <div>
        <ListOffice />
      </div>
    </>
  );
}
