import { useNavigate } from "react-router-dom";

export default function ButtonTypeHidden(props) {
  const navigate = useNavigate();

  const redirectLink = (props) => {
    if (props.link != undefined) {
      identificationTarget(props.link, props.targetBlank);
    } else {
      navigate(""); // target == _self
    }
  };

  const identificationTarget = (link, blank) => {
    if (blank != undefined) {
      if (blank == true) {
        window.open(link, "_blank");
      } else {
        navigate(link); // target == _self
      }
    } else {
      navigate(link); // target == _self
    }
  };

  return (
    <>
      <a style={{ cursor: "pointer" }} onClick={() => redirectLink(props)}>
        {props.children}
      </a>
    </>
  );
}
