import NavMenu from "../../../components/public/others/Navbar";
import NavMenuCM from "../../../components/public/others/NavbarCM";
import Search from "../../../components/public/others/search/SearchHome";
import Buttons from "../../../components/public/buttons/ButtonHome"
import NewsAll from "../../../components/public/news/NewsAll";
import Footer from "../../../components/public/others/Footer";
import { TipoPortal } from "../../../services/variables";
import buttonsNavbar from "../../../components/jsons/jsonButtons"
import buttonsNavbarCM from "../../../components/jsons/jsonButtonsCM"

export default function NewsPage() {

  return (

    <>
      {TipoPortal == 3 ? <>
      <NavMenu/>    
      <Search/> 
      <Buttons names={buttonsNavbar}linkFilter={["Coronavírus (Covid-19)","Notícias","Diário Oficial","e-SIC","Nota Fiscal","Mapa do Site","Nota Fiscal Eletrônica","2ª via do IPTU","Certidões","Contracheque","Perguntas e Respostas"]}></Buttons>   
      <NewsAll/> 
      <Footer/>
    </>:<>
      <NavMenuCM/>
      <div className="spaceDivCM"></div>
      <div className="GroupBottomCM">
     <Buttons names={buttonsNavbarCM}linkFilter={["Coronavírus (Covid-19)","Notícias","Diário Oficial","e-SIC","Nota Fiscal","Mapa do Site","Nota Fiscal Eletrônica","2ª via do IPTU","Certidões","Contracheque","Perguntas e Respostas"]}></Buttons>   
      <NewsAll/> 
      <Footer/>
      </div>
    </>}
    </>
  )
}
