import NavMenu from "../../../../components/public/others/Navbar";
import NavMenuCM from "../../../../components/public/others/NavbarCM";
import Footer from "../../../../components/public/others/Footer";
import { TipoPortal } from '../../../../services/variables';
import MenuSalaEmpreendedor from '../../../../components/public/transparency/salaEmpreendedor/MenuEmpreendedor';
import SubTitle from '../../../../components/public/others/title/SubTitle';
import ButtonServices from "../../../../components/public/transparency/salaEmpreendedor/ButtonService";


export default function servicosSalaEmpreendedor(props) {
    const route = props.route
  
    return (
      <> 
       {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
   {TipoPortal == 3 ? <>
        <NavMenu/>
          </>:<>
        <NavMenuCM/>
        </>}
        <div className="section-title rotes_pages">
        <h6>Início {route}</h6>
        </div>
        <div className="section-title-portarias">
        <h4>Sala Empreendedor</h4>
        </div>
        <MenuSalaEmpreendedor filter={'Sala Digital'}/>
        <SubTitle subTitle={'Serviços'} border={true}/>
        <div className="section-title">
        <ButtonServices/>
        </div>
        <br/>
        <Footer/>
        
      </>)}
