import EditNews from "../../../components/admin/news/Edit";
import AdminNavbar from "../../../components/admin/Navbar";
import { useParams } from "react-router-dom";

export default function AdminNewsPage() {
  const { idNoticias } = useParams();
  return (
    <>
      <AdminNavbar />
      <div>
        <EditNews id={idNoticias} />
      </div>
    </>
  );
}
