import NavMenu from "../../../components/public/others/Navbar";
import NavMenuCM from "../../../components/public/others/NavbarCM";
import Footer from "../../../components/public/others/Footer";
import UniqueNew from "../../../components/public/news/UniqueNews"
import { useParams } from "react-router-dom";
import { TipoPortal } from "../../../services/variables";

export default function NewsPage() {
     const {idNoticias} = useParams()
  return (

    <>
     {TipoPortal == 3 ? <>
      <NavMenu/>   
    </>:<>
      <NavMenuCM/>
    </>} 
      <UniqueNew idNoticias={idNoticias}/>      
      <Footer/>
    </>
  )
}
