import NavMenu from "../../../components/public/others/Navbar";
import NavMenuCM from "../../../components/public/others/NavbarCM";
import SearchHome from "../../../components/public/others/search/SearchHome";
import Buttons from "../../../components/public/buttons/ButtonHome";
import Banner from "../../../components/public/img/Banner";
import News from "../../../components/public/news/News";
import Footer from "../../../components/public/others/Footer";
import buttonsNavbar from "../../../components/jsons/jsonButtons";
import buttonsNavbarCM from "../../../components/jsons/jsonButtonsCM";
import { TipoPortal } from "../../../services/variables";

export default function Home() {
  return (
    <>
      {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
      {TipoPortal == 3 ? (
        <>
          <NavMenu />
          <SearchHome />
          <br />

          <Buttons names={buttonsNavbar} linkFilter={["Inicio"]}></Buttons>
          <Banner />
          {/* <News/>  */}
          <br />
          <br />
          <Footer></Footer>
        </>
      ) : (
        <>
          <NavMenuCM />
          <div className="spaceDivCM"></div>
          <div className="GroupBottomCM">
            <Buttons names={buttonsNavbarCM} linkFilter={["Inicio"]}></Buttons>
            <Banner />
            <News />
            <Footer></Footer>
          </div>
        </>
      )}
    </>
  );
}
