import "../../../../../../styles/filter_sacop.css";

export default function FiltrosSacopContrato(props) {
  const { portaria, setStatus, setUnidade } = props;

  if (portaria.length == 0) {
    return null;
  }

  return (
    <div className="container_filters">
      <label className="label_filter" htmlFor="SELECT_STATUS">
        <b>Filtrar Status</b>
        <select
          name="SELECT_STATUS"
          id="SELECT_STATUS"
          onChange={(e) => {
            setStatus(e.target.value);
          }}
        >
          <option value="">Todas</option>
          <option value="SUSPENSO">SUSPENSO</option>
          <option value="CANCELADO">CANCELADO</option>
          <option value="PENDENTE DE ENVIO AO TCE">
            PENDENTE DE ENVIO AO TCE
          </option>
          <option value="FRACASSADA">FRACASSADA</option>
          <option value="FINALIZADA">FINALIZADA</option>
          <option value="ENVIADO AO TCE">ENVIADO AO TCE</option>
          <option value="REVOGADA">REVOGADA</option>
          <option value="FINALIZADO">FINALIZADO</option>
          <option value="EM ANDAMENTO">EM ANDAMENTOA</option>
          <option value="HOMOLOGADA/RATIFICADA">HOMOLOGADA/RATIFICADA</option>
          <option value="EM AVISO">EM AVISO</option>
          <option value="DESERTA">DESERTA</option>
          <option value="ANULADA">ANULADA</option>
          <option value="DESATIVADO(TCE)">DESATIVADO(TCE)</option>
          <option value="EM INTENCAO DE LICITAR">EM INTENCAO DE LICITAR</option>
        </select>
      </label>
      <label className="label_filter" htmlFor="SELECT_UNIDADE">
        <b>Filtrar Unidade</b>
        <select
          name="SELECT_UNIDADE"
          id="SELECT_UNIDADE"
          onChange={(e) => {
            setUnidade(e.target.value);
          }}
        >
          <option value="">Todas</option>
          {Array.from(new Set(portaria.map((item) => item.NOME_UNIDADE)))
            .map((unidade) => {
              return portaria.find((item) => item.NOME_UNIDADE === unidade);
            })
            .map((item, index) => (
              <option key={index} value={item.NOME_UNIDADE}>
                {item.NOME_UNIDADE}
              </option>
            ))}
        </select>
      </label>
    </div>
  );
}
