import AdminNavbar from "../../../components/admin/Navbar";
import List from "../../../components/admin/news/Lists";
import React from "react";

export default function TestePage() {
  return (
    <div className="App">
      <AdminNavbar />
      <div>
        <List />
      </div>
    </div>
  );
}
