import styledCSS from "../../../../../styles/module/others/iconeSuspended.module.css";

export default function IconeRadar() {
  return (
    <>
      {/* icone radar */}
      <div
        className={styledCSS.cardRadar}
        title="Radar da Transparência Pública"
      >
        <a
          href="https://radardatransparencia.atricon.org.br/radar-da-transparencia-publica.html"
          target="blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={styledCSS.icone}
            viewBox="0 0 16 16"
          >
            <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM6 8a2 2 0 1 1 2.5 1.937V15.5a.5.5 0 0 1-1 0V9.937A2 2 0 0 1 6 8z" />
          </svg>
          <span>Radar da Transparência</span>
        </a>
      </div>
    </>
  );
}
