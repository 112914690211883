
import styledCSS from "../../../../../styles/module/others/iconeSuspended.module.css" 


export default function IconeAcessibilidade() {


  return (
    <>
     
      <div 
      className={styledCSS.cardAcessibilidade}
      onClick={()=>document.getElementsByClassName('uai')[0].click()}
      >
        <svg xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 63 63" 
        className={styledCSS.icone}>
          <defs></defs>
          <title>Acessibilidade</title>
          <path 
          class="a" 
          d="M0,31.5A31.5,31.5,0,1,0,31.5,0,31.53,31.53,0,0,0,0,31.5Zm3,0A28.5,28.5,0,1,1,31.5,60,28.54,28.54,0,0,1,3,31.5Z"/>
          <path class="b" d="M36.5,48.86A9.24,9.24,0,0,0,41,31.54V27.7A12.74,12.74,0,1,1,24.16,42.78h3.66A9.26,9.26,0,0,0,36.5,48.86Z"/>
          <circle class="b" cx="31.5" cy="12.61" r="4"/>
          <path class="b" d="M19.66,36.33h0l.3-.56a1.73,1.73,0,0,1,1.25-.88L29.5,33.5V25l-2.94,2.23a1.77,1.77,0,0,1-1.06.35H19.17a1.75,1.75,0,0,1,0-3.5h5.74l6.42-4.86a1.47,1.47,0,0,1,.48-.25,4,4,0,0,1,5.69,3.61v13a4,4,0,0,1-4,4c-2.23,0-11.3-.63-11.3-.63L17,48.45a1.76,1.76,0,0,1-1.54.91,1.83,1.83,0,0,1-.84-.21,1.75,1.75,0,0,1-.9-1.37,1.8,1.8,0,0,1,.2-1Z"/>
          <path class="b" d="M12.17,23.53a1,1,0,0,1,1.34.45l3.07,6.13H23a1,1,0,0,1,0,2H16a1,1,0,0,1-.9-.55l-3.34-6.68A1,1,0,0,1,12.17,23.53Z"/></svg>
       <span>
         Acessibilidade
        </span>
      </div>
      </>
  );
}
