import Acts from "../../../components/jsons/routesTranparency/routesAtcsAdministrative";
import NavMenu from "../../../components/public/others/Navbar";
import NavMenuCM from "../../../components/public/others/NavbarCM";
import Footer from "../../../components/public/others/Footer";
import { TipoPortal } from "../../../services/variables";
import HistoryBienios from "../../../components/public/others/ordinances/HistoryBienios";


export default function historyBienios(props) {

  

  return (
    <> 
     {TipoPortal == 3 ? <>
      <NavMenu/>    
      <div className="section-title rotes_pages">
      <h6>Início / Informações Institucionais / Mural / Histórico</h6>
      </div>    
    </>:<>
      <NavMenuCM/>
    <div className="section-title rotes_pages">
      <h6>Início / Legislativo Municipal  / Mural / Histórico</h6>
    </div>
    </>}
    
    
    <div className="section-title-portarias">
      <h4>Histórico dos Biênios</h4>
    </div>
    <HistoryBienios/> 
     
      <Footer/>
    </>
  )
}