import AdminNavbar from "../../../components/admin/Navbar";
import ListBanners from "../../../components/admin/banner/List";

export default function AdminListBanner() {
  return (
    <>
      <AdminNavbar />
      <div>
        <ListBanners />
      </div>
    </>
  );
}
