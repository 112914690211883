import styles from "../../../../../../styles/module/transparency/cardSacop.module.css";
import ButtonTypeHidden from "./../../../../others/utils/ButtonTypeHidden";

export default function CardSacop(props) {
  const data = props.data;
  const name = props.name;

  const status = [
    {
      color: styles.greenStatus,
      status: [
        {
          nome: "ENVIADO AO TCE",
        },
        {
          nome: "FINALIZADO",
        },
        {
          nome: "FINALIZADA",
        },
        {
          nome: "HOMOLOGADA/RATIFICADA",
        },
      ],
    },
    {
      color: styles.yelowStatus,
      status: [
        {
          nome: "PENDENTE DE ENVIO AO TCE",
        },
        {
          nome: "EM ANDAMENTO",
        },
        {
          nome: "EM AVISO",
        },
        {
          nome: "EM INTENCAO DE LICITAR",
        },
      ],
    },
    {
      color: styles.redStatus,
      status: [
        {
          nome: "REVOGADA",
        },
        {
          nome: "FRACASSADA",
        },
        {
          nome: "DESERTA",
        },
        {
          nome: "ANULADA",
        },
        {
          nome: "CANCELADO",
        },
        {
          nome: "DESATIVADO(TCE)",
        },
        {
          nome: "SUSPENSO",
        },
      ],
    },
  ];

  return (
    <section className={styles.card}>
      <div
        className={styles.box}
        style={{
          flex: "0.5",
        }}
      >
        <div className={styles.info_box}>
          {status.map((item) =>
            item.status.map((status) =>
              status.nome === data.STATUS_LICITACAO_NOME ? (
                <div
                  key={status.nome}
                  className={`${styles.status} ${item.color}`}
                >
                  <p>Identificação</p>
                  <b>
                    {data.CODIGO}
                    {data.NUMERO_INSTRUMENTO}
                    {data.NUMERO_INSTRUMENTO.includes(
                      data.DATA_PUBLICACAO.split("/")[2]
                    )
                      ? null
                      : data.DATA_PUBLICACAO.split("/")[2]}
                    {data.NUMERO_INSTRUMENTO == "" ? (
                      <>
                        i{data.NUMERO_PROCESSO}
                        {data.DATA_ENVIO.split("/")[2]}
                      </>
                    ) : null}
                  </b>
                </div>
              ) : null
            )
          )}
          <br />
          <div className={styles.sequencia}>
            <b>Sequência: </b>
            {data.SEQUENCIA}
          </div>
        </div>
      </div>
      <div
        className={styles.box}
        style={{
          flex: "2",
        }}
      >
        <div className={styles.container_item}>
          <div className={styles.container_icone}>
            <picture>
              <img src="/icons/clarity_date-line.svg" alt="data_icon" />
            </picture>{" "}
            <b>Exercício: </b>
            {data.EXERCICIO}
          </div>
          {data.DATA_PUBLICACAO !== "" ? (
            <div>
              <b>Data Publicação: </b>
              {data.DATA_PUBLICACAO}
            </div>
          ) : (
            <div>
              <b>Data Publicação: </b>
              N/A
            </div>
          )}
          {data.DATA_ABERTURA !== "" ? (
            <div>
              <b>Data Abertura: </b>
              {data.DATA_ABERTURA}
            </div>
          ) : (
            <div>
              <b>Data Abertura: </b>
              N/A
            </div>
          )}

          <div className={styles.ultima_movimentacao_interno}>
            <b>Ultima Movimentação: </b>
            {data.DATA_ENVIO}
          </div>
        </div>
        <div className={styles.container_item}>
          <div className={styles.container_icone}>
            <picture>
              <img src="/icons/mdi_database-cog.svg" alt="mdi_database" />
            </picture>{" "}
            <b>Número do Processo: </b>
            {data.NUMERO_PROCESSO}
          </div>
          {data.NUMERO_INSTRUMENTO !== "" ? (
            <div>
              <b>Número do Instrumento: </b>
              {data.NUMERO_INSTRUMENTO}
            </div>
          ) : (
            <div>
              <b>Número do Instrumento: </b>
              N/A
            </div>
          )}
          <div>
            <b>Valor total da despesa: </b>
            {data.VALOR_TOTAL_DESPESA}
          </div>
        </div>
        <div className={styles.container_item}>
          <div className={styles.container_icone}>
            <picture>
              <img
                src="/icons/icon-park-outline_info.svg"
                alt="icon-park-outline_info"
              />
            </picture>{" "}
            <b>Modalidade: </b>
            {data.MODALIDADE_NOME !== "" ? data.MODALIDADE_NOME : "N/A"}
          </div>
          {/* <div>
            <b>Tipo: </b>
            {data.TIPO_LICITACAO_NOME !== "" ? data.TIPO_LICITACAO_NOME : "N/A"}
          </div> */}
          {/* <div>
            <b>Tipo Objeto: </b>
            {data.TIPO_OBJETO_NOME !== "" ? data.TIPO_OBJETO_NOME : "N/A"}
          </div> */}
        </div>
        <div className={styles.container_item}>
          <div className={styles.container_icone}>
            <picture>
              <img src="/icons/bi_building-fill.svg" alt="bi_building-fill" />
            </picture>{" "}
            <b>Unidade: </b>
            {data.UNIDADE_NOME}
          </div>
        </div>
        <div className={styles.box}>
          <a
            href={`/licitacoes-e-contratos/detalhes/${data.ID}`}
            target="_self"
          >
            <b>Objeto: </b>
            {data.OBJETO}
          </a>
        </div>
        {data.DOC_RESULTADO_CERTAME != undefined ? (
          <p className={styles.textComplement}>
            <ButtonTypeHidden
              link={
                "http://www.transparenciadministrativa.com.br/portalimagem/" +
                data.CNPJ.replace("/", "")
                  .replace(".", "")
                  .replace(".", "")
                  .replace("-", "") +
                "/" +
                data.DOC_RESULTADO_CERTAME
              }
              targetBlank={true}
            >
              Resultado Certame
            </ButtonTypeHidden>
          </p>
        ) : null}
      </div>
      <div
        className={styles.box}
        style={{
          flex: "0.3",
        }}
      >
        <a
          href={`/licitacoes-e-contratos/detalhes/${data.ID}`}
          target="_self"
          className={styles.btn}
        >
          DETALHAR
        </a>
        <div className={styles.info_box}>
          {status.map((item) =>
            item.status.map((status) =>
              status.nome === data.STATUS_LICITACAO_NOME ? (
                <div
                  key={status.nome}
                  className={`${styles.status} ${item.color}`}
                >
                  {status.nome}
                </div>
              ) : null
            )
          )}
        </div>
      </div>
      {name == "Avisos" ? null : (
        <div className={styles.ultima_movimentacao}>
          <b>Ultima Movimentação: </b>
          {data.DATA_ENVIO}
        </div>
      )}
    </section>
  );
}
