import NavMenu from "../../../components/public/others/Navbar"
import NavMenuCM from "../../../components/public/others/NavbarCM"
import SearchHome from "../../../components/public/others/search/SearchHome";
import Buttons from "../../../components/public/buttons/ButtonHome"
import Footer from "../../../components/public/others/Footer";
import MapSite from '../../../components/public/buttons/MapSite'
import { TipoPortal } from "../../../services/variables";


import buttonsNavbar from '../../../components/jsons/jsonButtons'
import buttonsNavbarCM from '../../../components/jsons/jsonButtonsCM'
export default function mapSite() {
 
  return ( 
    <>
      {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
    {TipoPortal == 3 ? <>
      <NavMenu/>    
      <SearchHome></SearchHome> 
      <Buttons names={buttonsNavbar}linkFilter={["Mapa do Site"]}></Buttons>
      <MapSite/>
      <Footer></Footer>     
    </>:<>
      <NavMenuCM/>
      
      <div className="spaceDivCM"></div>
      <div className="GroupBottomCM">
      <Buttons names={buttonsNavbarCM}linkFilter={["Mapa do Site"]}></Buttons>
      <MapSite/>
      <Footer></Footer> 
      </div>
    </>}

 
    </>
  )
}


