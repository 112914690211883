import { useState, useEffect } from 'react';
import { Bar, Doughnut } from "react-chartjs-2";
// import { Bar } from "react-chartjs-2";
import styled from '../../../../styles/module/transparency/e-sic/result/graphic.module.css';
import { Chart as ChartJS } from "chart.js/auto";
import Form from 'react-bootstrap/Form';


const month= ['1','2','3','4','5','6','7','8','9','10','11','12'];
const dataMonths = ['Janeiro', 'Fevereiro','Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

export default function BarChartESIC({data,years,summaryOfAllYears}){

  const [dataYear,setDataYear] = useState({}) 
  const [currentData,setcurrentData] = useState([]) 
  const [yearCurrent, setYearCurrent] = useState()

  console.log(summaryOfAllYears)

  useEffect(() =>{
    const saveYearCurrent = years[0]
    setYearCurrent(years[0])
    setcurrentData(data[saveYearCurrent])
  },[])



  
  
  const changeCurrentYear = (year) =>{
    console.log('mudando ano', year);
    setcurrentData(data[year]);
    setYearCurrent(year);
  }


  useEffect(()=>{

    setUserData({
      labels: dataMonths.map((item) => {return item}),
      
      datasets: [
        {
          label: 'Pendentes',
          data: currentData.map((item) => item['PENDENTE']), 
          backgroundColor: [`rgb(242, 214, 29)`],
        },
        {
          label: 'Encerrados',
          data: currentData.map((item) => item['FINALIZADO']),  
          backgroundColor: [`rgb(3, 156, 29)`],
        },
        {
          label: 'Total de perguntas',
          data: currentData.map((item) => item['TOTAL_PEDIDOS']),
          backgroundColor: [`rgb(65, 66, 65)`],
          type: 'line'
        },
        

      ],
    });
  },[yearCurrent])
  
    const [userData,setUserData] = useState({
        labels: dataMonths.map((item) => {return item}),
        datasets: [
          {
            label: 'Pendentes',
            data: [], 
            backgroundColor: [`rgb(242, 214, 29)`],
          },
          {
            label: 'Encerrados',
            data: [],  
            backgroundColor: [`rgb(3, 156, 29)`],
          },
          {
            label: 'Total de perguntas',
            data: [],
            backgroundColor: [`rgb(65, 66, 65)`],
            type: 'line'
          },
  
        ],
      });
     

      const [dataSummary, setDataSummary] = useState({
        labels: ['Pendente','Encerrados'],
        datasets: [
          {
            label: "Quantidade",
            data: [summaryOfAllYears['sumAllPending'],summaryOfAllYears['sumAllFinished']],
            backgroundColor: [
              "rgb(242, 214, 29)",
              "rgb(3, 156, 29)",
            ],
            hoverOffset: 4
            
          },
        ],
      });


    
    return(
    <>
          


  {Object.keys(data).length !== 0 ? <>
      <div className={styled.boxInfor}>

        <div className={styled.inforAlert}>

        <svg title="aviso" className={styled.iconeInfor}  width="30" height="30" viewBox="0 0 16 16"><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path></svg>
        Selecione o ano que deseja verificar os resultados
        </div>
        <p>FoRAM encontradoS dados dos anos: </p>
      </div>
          <Form className={styled.boxYear}>
      {years.map((year,i) =>{
        return (
                <Form.Check
                  inline
                  label={year}
                  name="group1"
                  type={'radio'}
                  key={i}
                  onClick={()=>{changeCurrentYear(year)}}
                />)
        })}
        
    </Form>
    
    <div className={styled.boxGraphic}>
      <div className={styled.graphic}>

    <Bar 
    options={{
      
      interaction: {
        mode: 'index',
        intersect: false,
      },
      plugins: {
        title: {
          display: true,
          text: `Perguntas do ano ${yearCurrent}`,
          align: "center",
        },

        layout: {
          margin: {
            left: 'auto',
            right: 'auto',
            top: 5,
            bottom: 5,
          },
        }
        
        
      }
    }}
    data={userData}/>
        </div>
    </div>

    <div className={styled.boxInfor}>

<div className={styled.inforAlert}>

O gráfico Abaixo é resultado da soma de Pendentes e encerrados de todos os anos: 
</div>
<p>Foram encontradas {summaryOfAllYears['sumAllOrders']} perguntas noS anos ( 
      {years.map((year,i) =>{
        return (<> {i == 0 ? <>{year}</> : <>, {year}</>}  </>)
      })
    }
    )</p>
</div>
    <div className={styled.boxGraphic}>

    <Doughnut data={dataSummary} style={{margin:'0 auto'}}/>
</div>
    </>: <section className="footer-pagination-table"><h6 className="resultTxt ordinances-result">Nenhum Resultado Encontrado</h6></section>}
    
    </>
    )
  }
  
