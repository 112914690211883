import styles from "../../../styles/loading.module.css";

export default function Loading({ progress }) {
  return (
    <div className={styles.containerLoaderFull}>
      <img
        src="/icons/loading.svg"
        alt="Carregamento"
        className={styles.loaderFull}
      />
      {progress && (
        <p className={styles.loaderText}>Carregando... {progress}%</p>
      )}
    </div>
  );
}
