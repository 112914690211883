import LayoutFlow from "./Views";
import { useEffect, useState } from "react";
import React from 'react';
import 'reactflow/dist/style.css';
import Loading from '../Loading'
import api from "../../../../services/api";
import styled from '../../../../styles/module/others/organograma.module.css';
import ListAtos from './FilesAtos'

export default function App(){
  const [data,setData] = useState(false);
  const [showView,setShowView] = useState(false);
  const [dataNodes,setNodes] = useState([]);
  const [dataEdges,setEdges] = useState([]);

  //Informações comuns dos nodes e edges
  const position = { x: 0, y: 0 };
  const edgeType = 'smoothstep';
  const styleNode =  { 
    border: '2px solid #777', 
    padding: 10,background: '#000080',
    color:'#FFFF', 
    "font-weight": '600'
  };
  const styleEdges ={ stroke: '#463F3F' };


  const convertData= ()=>{
    const arrayNodes =[];
    const arrayEdges =[];
     
    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      
      const id = element['ORDEM_DOS_CARDS']['ID_CARD_ATUAL'];
      const label = element['TITULO'];
      arrayNodes.push({
            id,
            data: { label, element:element },
            position,
            style: styleNode,
      })

      const compoundKey = element['ORDEM_DOS_CARDS']['ID_CARD_PAI'] +"-"+ element['ORDEM_DOS_CARDS']['ID_CARD_ATUAL'];
      const source = element['ORDEM_DOS_CARDS']['ID_CARD_PAI'];
      const target = element['ORDEM_DOS_CARDS']['ID_CARD_ATUAL'];
      arrayEdges.push(
      { 
        id: compoundKey, 
        source, //Começo
        target, //Destino
        type: edgeType, 
        animated:  true,
        style: styleEdges  
      },)

      setNodes(arrayNodes);
      setEdges(arrayEdges);
      
    }
    
    console.log(data)
    if(arrayNodes.length != 0 && arrayEdges.length != 0){
      setShowView(true);
    }
  }

  useEffect(()=>{
    convertData();    
  },[data])

  useEffect(()=>{
    api.get('/organograma').then(({data})=>{
      console.log(data)
      const result = data.res;
      if(result != undefined && !result.error){
        setData(result);
      }else{
        // console.log('Error na resposta');
      }
    }).catch((err)=> { 
      // console.error(err);
    })  
      
  },[])

  return (
      <div className={styled.boxOrganograma} >
        <ListAtos/>
        {showView ? <LayoutFlow nodes={dataNodes} edges={dataEdges}/> : <></>} 
        
        {data ? <>
        </> : <Loading/>}

      </div>
  );
};

