import styled from "../../../../styles/module/transparency/salaEmpreendedor/main.module.css"
import Menu from "./json/menu.js"
import styledComponent from 'styled-components';

export default function MenuSalaEmpreendedor(props) {
    const {filter} = props;

    return(<>
    <section className={styled.gridPage}>

        <section className={styled.empreendedor} id='gridSalaEmpreendedor'>
       
        {Menu.map((i,key)=>{
            
            const Box = styledComponent.div`
                background-image: url('${i.img}');
                background-position: center; /* Center the image */
                background-repeat: no-repeat; /* Do not repeat the image */
                background-size: cover; 
                border-radius: 15px;
                display: flex;
                flex-direction: column;
                justify-content:  flex-end;

                &:hover {
                    cursor:pointer;
                    scale: 1.05;
                    transition: 2s;
                }
            `;
            return(
                <>

                {filter == i.title ? <></>:
                 
                <Box img={i.img} key={key}>

                
                <a key={key} className={styled.bodyCardText} href={i.link} title={i.title}>
                <h4 className={styled.title}>{i.title}</h4>
                <p className={styled.bodyText}>{i.body}</p>
                </a>
                </Box>
                }
                </>
           )})}
           </section>
    </section>
    
   
    </>)
}