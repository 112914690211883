




const buttonsTools = [
    {
    name:`Controle de Fluxo de Caixa       `,
    link:`https://docs.google.com/spreadsheets/d/1se4zXnz5czlpB2S1HGcQPvFTB7r62U8d/edit#gid=999715136`,
    },
    {
        name:`Plano de Ações          `,
        link:`https://docs.google.com/spreadsheets/d/1ZzA3xnNjd_Mn5nyBb4yCu9YNcZbYht_I/edit?rtpof=true&sd=true#gid=1984751906`,
    },
    {
        name:`Pesquisa de Satisfação de Cliente        `,
        link:`https://docs.google.com/spreadsheets/d/1ah7sB1zkcPFw_GEyBCirmqgC_94dz3gv/edit#gid=328818253`,
    },

    {
        name:`Modelo de Negócio     `,
        link:`https://canvas-apps.pr.sebrae.com.br/#/?checkedSAS=true`,
    },
   
   
]
export default buttonsTools;



